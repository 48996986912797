<template lang='pug'>
vue-tel-input.fi-phone-input(
  v-bind              = '$attrs'
  :preferredCountries = 'countries'
  :input-options      = '{ showDialCode: true }'
  :default-country    = 'defaultCountry'
  mode                = 'international'
  dynamicPlaceholder
  @input              = 'setValue')
</template>


<script>
import { VueTelInput } from 'vue-tel-input'
import { mapGetters } from 'vuex'

export default {
  name: 'fi-phone-input',

  components: { VueTelInput },

  inheritAttrs: false,

  props: {
    countries: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters('settings', ['settingByKey']),
    defaultCountry () {
      return this.settingByKey('country')
    }
  },

  mounted () {
    this.$el.classList.remove('vue-tel-input')
  },

  methods: {
    setValue (value, { number, valid }) {
      this.$emit('input', number.e164)
    }
  }
}
</script>


<style lang='scss' scoped>
.fi-phone-input {
  display: flex;

  ::v-deep {
    .vti__input {
      &:disabled, &[readonly] {
        background-color: #c2cfd6;
        opacity: 1;
      }
    }
  }
}
</style>
